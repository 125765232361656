.wrapper {
  border-bottom: 1px solid var(--grey);

  > div:first-child {
    display: flex;
    margin-bottom: 2rem;
    > div {
      flex-grow: 1;

      > div {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;

        > div {
          min-width: 50%;
        }
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap-reverse;

      > div {
        flex-basis: 100%;

        &:last-child {
          margin-bottom: 1rem;
        }

        [class*='ui large input'] {
          width: 100% !important;
        }
      }
    }
  }

  > div:nth-child(2) .accordion {
    border-top: none;
  }
}

.textMuted {
  color: #777;
}

.container {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 20;
  padding-top: 1.75rem;
}

.boardWrapper {
  width: 100%;
}

.accordion {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
  min-height: 4.5rem;
  color: black;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid var(--grey);
  padding: 1.5rem 0 0 0;

  > img {
    display: block;
    vertical-align: middle;
    width: 1.5rem;
    height: 0.5rem;
    margin-top: 0.5rem;
  }
  > span {
    padding-left: 1.5rem;
    cursor: pointer;
    display: block;
    flex-grow: 1;
    max-width: calc(100% - 5.5rem);
  }

  &.collapsed {
    min-height: 72px;
    overflow: hidden;
    padding: 1.5rem 0;

    > .boardWrapper {
      display: none;
    }

    > img:first-child {
      transform: rotate(-90deg) translate(2px, 0);
    }
  }
}

.solutionsSummary {
  padding-right: 1.5rem;
  width: 4rem;
  img {
    margin-right: 0.5rem;
  }
  img,
  span {
    vertical-align: middle;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16rem;
  background: var(--grey4);
  border-radius: 0.75rem 0.75rem 0 0;
  flex: 1 0 0;
  margin: 0 0.5rem;

  &:nth-child(1) {
    margin-left: 0;
  }
  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.smallerAvatar {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.625rem;
  line-height: 1.5px;
  margin: 0 0 -0.25px 0;
  border: 2px solid white;
}

.title {
  padding: 1rem;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
  color: var(--grey3);
  &::before {
    content: '';
    display: inline-block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    vertical-align: 1px;
  }
}

@for $i from 0 to 5 {
  .column-$i {
    .title {
      border-left-color: var(--column-$i) !important;
      &::before {
        background: var(--column-$i);
      }
    }

    [class*='QuoteList_dropZone_'] > a > div[class*='ui card'] {
      border-left: 0.25rem solid var(--column-$i) !important;
    }
  }
}
