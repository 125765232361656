.select {
  min-width: 128px !important;
  max-height: 3rem !important;
  border-left: none !important;
}

.tagify {
  border-right: 1px solid rgba(34,36,38,.15) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  > tags {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }
}

@media (max-width: 768px){
  [class*=actions]{
    display: flex;
    flex-wrap: wrap-reverse;

    button{
      margin-left: 0 !important;
      width: 100%;
    }
  }
}