footer{
  position: relative;
  z-index: 1000;
}

.footer{
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  z-index: 10000;
  padding: 1rem 2rem;
  display: flex;
  justify-content: stretch;

  > span, a{
    display: block;
    color: var(--grey3);
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: 0.5;
  }

  > div {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;

    > a{
      transition: all 0.2s ease-in-out;

      &:hover{
        color: var(--grey2);
      }

      &:not(:last-child){
        margin-right: 0.3rem;
        &:after{
          content: '|';
          margin-left: 0.3rem;
        }
      }
    }
  }
}

div[class*=modals]{
  height: calc(100% - 3rem) !important;
}