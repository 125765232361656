.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px){
    flex-wrap: wrap-reverse;

    > div{
      flex-basis: 100%;

      div{
        width: 100%;
      }

      &:last-child{
        margin-bottom: 1rem;
      }

      > a{
        width: 100% !important;

        > button{
          width: 100% !important;
        }
      }
    }
  }
}

.sticky {
  background: white !important;
  position: sticky;
  top: 6rem;
  z-index: 9;
}

.inputSearch {
  width: 350px;

  i[class*="link icon"] {
    height: 46px !important;

    &[hidden] {
      display: none;
    }

    img[class*="ui image"] {
      height: 22px;
    }
  }
}

.tableCell {
  &Email,
  &Invited {
    color: var(--grey2);
  }
  &Edit {
    height: 32px;
    text-align: center;
    width: 100%;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }
  }
}

.nameHeader {
  padding-left: 74px !important;
}

.nameHeader,
.firstNameHeader {
  min-width: 250px;
}
.lastNameHeader {
  min-width: 150px;
}
.statusHeader {
  min-width: 120px;
}
.roleHeader {
  min-width: 80px;
}

.editHeader{
  width: 2rem;
}
.loggedAtHeader {
  width: 13rem;
}

.firstNameHeader {
  padding-left: 16px !important;
}

.centered,
.editHeader,
.statusHeader {
  text-align: center !important;
}

.firstNameHeader:not(.sorted)::after,
.nameHeader:not(.sorted)::after {
  display: inline-block !important;
  content: '' !important;
  height: 1em !important;
  width: 8.75px !important;
  margin-left: 7px !important;
}

@media (max-width: 768px){
  .nameHeader,
  .firstNameHeader {
    min-width: 5rem !important;
  }
  .lastNameHeader, [class*=tableColumnLastName] {
    min-width: 3rem !important;
    display: none;
  }
  .statusHeader {
    min-width: 3rem !important;
  }
  .roleHeader {
    min-width: 3rem !important;
  }

  .icon{
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    margin: 0 0.5rem 0 0.25rem;
  }

  .tiles > [class*="ui card"] > [class="extra content"]{
    span:last-child{
      display: none;
    }
  }
}

.dots {
  fill: var(--primary) !important;
}

.pagination {
  background: white;
  padding: 8px 0 40px;
  border-top: 1px solid rgba(34, 36, 38, 0.1);
  text-align: center;

  span {
    vertical-align: middle;
  }

  button[class*="ui mini"] {
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0 16px;
    background-color: white !important;
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;

    path {
      stroke: var(--primary);
    }
  }
}

.previous {
  transform: rotate(90deg);
  margin-right: 2px;
}

.next {
  transform: rotate(-90deg);
  margin-left: 2px;
}

.tiles {
  flex-wrap: wrap;
  margin-top: 32px !important;

  &.member {
    margin-top: 0 !important;

    [class*='Tile_dots_'] {
      display: none;
    }
  }

  @media (max-width: 1200px){
    max-width: 100%;
    > [class*="ui card"]{
      flex-basis: 100%;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}
