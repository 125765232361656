[class*='solutionContainer'] {
  padding: 0 !important;
  &,
  [class*='App_content_'] {
    background: var(--grey4);
  }
  [class*='App_container_'] {
    &::before {
      content: '';
      height: 97px;
      width: 1000vh;
      background-color: white;
      position: absolute;
      left: -50%;
      top: 0;
      border-bottom: 1px solid var(--grey2);
    }
    &::after {
      border-bottom: 1px solid var(--grey2);
    }
  }
}

.wrapper {
  max-width: 42.75rem;
  min-height: calc(100vh - 9.5rem);
  margin: 0 auto 0;
  padding: 4rem 4rem 0;
  border-radius: 0.375rem;
  background: white;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  p:not([class*='_question_']) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
  }

  @media (max-width: 768px) {
    padding: 2.5rem 1.5rem 0;
    margin: -1rem -1rem 0;
  }
}

.header {
  height: 6rem;
  z-index: 2;
}

.cardHeader {
  position: relative;
  margin-bottom: 1rem;

  > div {
    position: absolute;
    top: -2.65rem;
    right: -0.5rem;
    height: 0.875rem;
    min-width: 0.5rem;
    text-align: center;

    > svg {
      height: 100%;
      width: auto;
    }

    &:hover {
      cursor: pointer;
    }
  }

  > a {
    position: absolute;
    top: -2.5rem;
    right: -2.5rem;
  }

  @media (max-width: 768px) {
    > div {
      top: -1.5rem;
      right: 1.5rem;
    }

    > a {
      top: -1.375rem;
      right: -0.5rem;
    }
  }
}

.breadcrumbs {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;

  span {
    opacity: 0.5;
    color: black;
    &:not(:last-child)::after {
      content: '/';
      display: inline-block;
      margin: 0 1rem;
    }
    &.active {
      color: initial;
      opacity: 1;
      &:not(:last-child)::after {
        opacity: 0.5;
      }
    }
    @media (max-width: 768px) {
      &:not(.active) {
        display: none;
      }
    }
  }
}

.title {
  font-weight: bold;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  margin: 0 0 3rem 0;
}

.questionnaire > div {
  margin: 3rem 0 3rem 0;
}

.authorContainer {
  border-top: 1px solid var(--grey) !important;
  margin: 0 -2rem 3rem;
  display: flex;
  justify-content: space-between;
  height: 5.5rem;
  align-items: flex-end;
  padding: 0 2rem !important;
}

div[class*='ui card'].solution {
  width: 100%;
  margin: 0 0 3rem;
  padding: 0;
  border-bottom: 1px solid var(--grey) !important;
  border-radius: 0;
  div[class*='content'] {
    padding: 0;
    display: flex;
    align-items: center;
    border-top: none !important;
    position: relative;
    margin-bottom: 1rem;

    > span {
      color: var(--grey2);
      font-weight: bold;
      font-size: 0.75rem !important;
      line-height: 1rem !important;
      display: inline-block;
      margin: 0;
    }

    .socialIcons,
    .submitted {
      right: 0;
      position: absolute;

      img {
        vertical-align: middle;
      }
    }

    .socialIcons span:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.actions {
  padding: 0 !important;
  & > div {
    img {
      width: 24px;
      height: 24px;
    }
    &:nth-child(2n-1) {
      float: left;
    }
    &:nth-child(2n) {
      float: right;
    }
    .smallerAvatar {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.625rem;
      line-height: 1.5rem;
      margin: 0 0 -0.25rem 0;
      border: 2px solid white;

      + span {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: black;
      }
    }
    img.smallerAvatar {
      border-radius: 50%;
      margin: 0;
      vertical-align: middle;
    }
    > span {
      margin: 0 0 0 1rem;
      > img {
        margin: 0 !important;
        display: inline-block !important;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
      }
      > span {
        color: black;
        vertical-align: middle;
        margin: 0 0.5rem;
      }
    }
  }
}

.actions {
  align-items: flex-end;
  display: flex;
  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 2rem;
    margin-left: 2.625rem;
  }
}

.author {
  align-items: flex-end;
  display: flex;
}

.smallerAvatar {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin: 0 0.5rem -0.25rem 0;
}

.comments {
  margin: 1rem 0;

  h2 {
    color: #000;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  textarea {
    &:focus {
      border-color: var(--blue) !important;
    }
  }

  .commentSubmit {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
  }
}

.footer {
  height: 6rem;
  border-top: 1px solid var(--grey);
  background: white !important;
  position: sticky;
  bottom: 0;
  z-index: 100;
  padding: 0 4rem;
  margin: auto -4rem 0;

  > div {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .status {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    border-radius: 2rem;
    color: black;
    padding: 0.375rem 1rem;
    background-color: var(--grey4);
    margin-left: 1rem;
    &:before {
      content: '';
      display: inline-block;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      vertical-align: 1px;
    }
    @for $i from 0 to 5 {
      &.column-$i {
        border-left-color: var(--column-$i) !important;
        &::before {
          background: var(--column-$i);
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: 6rem;
    height: auto;

    > div {
      margin: 1rem 0;

      > div {
        flex-grow: 1;
        button {
          width: 100%;
        }
        &:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.footerLinks {
  font-size: 0.75rem;
  line-height: 1rem;
  a {
    color: var(--grey2);
    padding: 0 0.25rem;

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child)::after {
      content: '';
      width: 1px;
      height: 0.625rem;
      display: inline-block;
      background-color: var(--grey);
      vertical-align: middle;
      margin-left: 0.375rem;
    }

    &:hover {
      color: black;
    }
  }
}

.footerSingle {
  font-size: 0.75rem;
  line-height: 1rem;
  width: 100%;
  text-align: right;
}
