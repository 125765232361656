.newNotification {
  position: relative;
  display: block;
  margin-right: 24px;
  cursor: pointer;

  &.new {
    &::before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background-color: var(--primary);
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media screen and (max-width: 768px){
    display: none;
  }
}

.modal {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06), 0px 0px 10px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  width: 424px;
  position: absolute;
  top: 80px;
  height: 512px;

  &::before {
    content: '';
    display: block;
    background: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06), 0px 0px 10px rgba(0, 0, 0, 0.16);
    width: 32px;
    height: 32px;
    transform: rotate(-45deg) translate(-50%, -50%);
    z-index: 0;
    position: absolute;
    top: -16px;
    left: 50%;
  }
}

.modalContainer {
  background: white;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

.smallerIcon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0 0.25rem 0.25rem !important;
  border-radius: 50%;
  font-size: 10px;
  line-height: 24px;
}

.notificationGroup {
  margin-bottom: 1.5rem;
  &:not(:last-child) {
    &::after {
      content: '';
      display: block;
      width: 392px;
      height: 1px;
      background-color: var(--grey);
      margin: 0 auto;
    }
  }
}

.notificationWrap {
  border-radius: 8px;
  overflow: hidden;
}

.notificationItem {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  padding: 24px 16px 0;
  
  h4 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 0 8px;
    color: black;
  }
}

.leftSide {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.datetime,
.details,
.description {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
  color: var(--grey2);
}

.details {
  color: black;
  padding: 0 1rem 0 3rem;
}

.description {
  padding: 0 1rem 1.5rem 3rem;
}
    