.top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse !important;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-child {
      flex-grow: 1;
    }

    @media (max-width: 768px) {
      flex-basis: 100%;

      a,
      button {
        width: 100% !important;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}

.challengesList {
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    display: flex;
    padding-bottom: 1rem;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  button[class*='primary'] {
    width: 308px;
    margin: 0 !important;
  }

  h3 {
    font-size: 1rem !important;
    font-weight: 400;
    color: var(--grey3);
  }
}

h3.draftList[class*='draftTitle'] {
  color: var(--grey3) !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.rightSidebar {
  min-width: 308px;
  max-width: 308px;
  margin-left: 40px !important;

  .content {
    flex-grow: 0 !important;
  }
  .challengesSummary {
    border: 1px solid var(--grey);
    border-radius: 4px;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin-bottom: 2.5rem !important;

    h5 {
      font-weight: normal;
      font-size: 0.875rem !important;
      color: var(--grey2);
      margin-bottom: 0.25rem;
    }
    span {
      display: block;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  .challengesLog {
    .challengesLogGroup {
      margin-bottom: 1.5rem;

      h3 {
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 2rem;
        color: var(--grey3);
        margin-bottom: 0.5rem;
        margin-left: 1rem;
      }

      .challengesLogWrap {
        border-radius: 8px;
        overflow: hidden;

        .challengesLogItem {
          background: rgba(0, 0, 0, 0.04);
          padding: 1rem;
          display: flex;
          align-items: stretch;
          margin-bottom: 0.25rem;

          div {
            flex-grow: 0;
            img {
              width: 100%;
              height: auto;
            }
            h4 {
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.5rem;
              margin-bottom: 0;
            }
            p {
              font-weight: normal;
              font-size: 0.875rem;
              line-height: 1.5rem;
              width: 100%;

              a {
                color: var(--primary);

                &:hover {
                  color: var(--primaryHover);
                  text-decoration: underline;
                }
              }
            }

            &:first-child {
              flex-shrink: 0;
              width: 1.5rem;
              margin-right: 0.5rem;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.tilesContainer {
  display: flex;
  flex-direction: column;
  width: 100% !important;

  &.active {
    width: calc(100% - 348px) !important;
    min-width: calc(100% - 348px) !important;
    max-width: calc(100% - 348px) !important;

    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }

  @media (max-width: 768px) {
    th,
    td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
  }
}

.smallerIcon {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0;
  border-radius: 50%;
  font-size: 10px;
  line-height: 24px;
}

.switchTileListView {
  margin-right: 28px;
  line-height: 1;

  svg {
    margin: 0 6px;
    cursor: pointer;
    &.active path,
    &:hover path {
      fill: var(--primary);
    }
    &.active rect,
    &:hover rect {
      stroke: var(--primary);
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
}
