.header {
  height: 6rem;
}

.inputWrapper {
  position: relative;
  > div[class*='field'] > div[class*='prompt label'] {
    bottom: -0.3rem !important;
    top: auto !important;
  }

  .helperText {
    /* margin-top: -1rem; */
    margin-bottom: -1.6rem;
    /* font-size: 0.75rem; */
    /* color: var(--grey2); */
  }
}

.wrapper {
  max-width: 677px;
  margin: 0 auto 2rem;

  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.editor {
  max-width: none;
  width: 100%;
}

.quitLink {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.75rem 1rem;
  color: black !important;
  cursor: pointer;
}

.breadcrumbs {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;

  span {
    opacity: 0.5;
    color: black;
    &:not(:last-child)::after {
      content: '/';
      display: inline-block;
      margin: 0 1rem;
    }
    &.active {
      color: initial;
      opacity: 1;
      &:not(:last-child)::after {
        opacity: 0.5;
      }
    }

    @media (max-width: 768px) {
      &:not(.active) {
        display: none;
      }
    }
  }
}

.upload {
  padding-left: 0 !important;

  width: 100%;
  label {
    width: 100%;
    border-color: var(--grey2);
    display: flex;
    flex-direction: column;
  }
  button,
  img:not([alt='background']) {
    left: 50% !important;
    transform: translateX(-50%);
  }
  img {
    margin-left: 0 !important;
    max-height: 170px !important;
    max-width: 98% !important;
  }
}

[class*='_inputTag_'] {
  [class*='tags-input'] {
    width: 100%;
  }

  tags {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.375rem;
    padding: 0.75rem 1rem;
    font-size: 1rem !important;

    span[class*='tagify__input'] {
      padding-left: 0 !important;
    }
    tag {
      padding: 0 !important;
      margin: 0 0.5rem 0 0 !important;

      div {
        &:before {
          box-shadow: none !important;
          background: var(--grey4);
        }
      }
    }
  }
}
