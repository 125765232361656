.inputTag {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  div[class*='ui input large'] {
    width: calc(100% - 128px);
  }
  tags {
    border-radius: 6px 0 0 6px;
    width: 100%;
    span[class*="tagify__input"] {
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      margin: auto 5px;
      &:empty::before {
        opacity: 0.1;
      }
    }
  }
  div[class*="ui selection dropdown"] {
    border-radius: 0 6px 6px 0 !important;
  }
  div[class*="ui active visible selection dropdown"] {
    border-top-left-radius: 0 !important;
  }

  &.error {
    position: relative;
    &::after {
      content: attr(data-message);
      color: var(--red);
      font-size: 12px;
      line-height: 16px;
      position: absolute;
      bottom: -20px;
    }
    tags {
      border-color: var(--red);
    }
  }
}

