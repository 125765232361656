$size: 11.875rem;
.wrapper {
  $paddingBottom: 2rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 33%;
  padding-bottom: $paddingBottom;
  height: calc($size + $paddingBottom);

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 1rem !important;
  }
}

.upload {
  height: 0.1px;
  width: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.fileSizeInfo {
  color: var(--grey2);
}

.label {
  border: 1px dashed var(--primary);
  border-radius: 8px;
  width: $size;
  height: $size;
  //background-image: url('/assets/icon-image.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;

  &.uploaded {
    background: none !important;
  }

  &:hover .onImage {
    opacity: 1;
    background-color: white !important;
    color: var(--primary) !important;
  }

  &:hover .close {
    opacity: 1;
    pointer-events: all;
  }
}

.wrapper .label button.button[class*='ui small inverted primary button'] {
  position: absolute;
  z-index: 2;
  border: 1px solid var(--primary);
  bottom: 12px;
  left: calc(50% - 76px);
  width: 152px;
  pointer-events: none;
  box-shadow: none !important;
}

.wrapper .label:hover button.button[class*='ui small inverted primary button'] {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16), 0px 0px 16px rgba(0, 0, 0, 0.06) !important;
}

.uploaded:not(.companyLogo) {
  background: none;
  border: none;

  .imageContainer {
    > img {
      border: 1px dashed var(--primary);
      margin-left: 16px;
      left: auto !important;
      transform: none !important;
      margin-top: 0 !important;
    }
  }
}

.onImage {
  opacity: 0;
}

.imageContainer {
  display: flex;
  justify-content: center;

  > img {
    left: auto !important;
    transform: translateY(-50%);
    margin-top: 50%;
  }
}

.preview {
  max-width: calc($size - 3px) !important;
  max-height: calc($size - 3px) !important;
  position: relative !important;
  display: inline-block !important;
  z-index: 1 !important;
  border-radius: 8px;
  pointer-events: none;
}

.close {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  display: inline-block;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  background: white url('/assets/icon-close-circle.svg') 50% 50% no-repeat;
  z-index: 2;
  vertical-align: top;
  padding: 11px;
}

.minimalError[class*='ui error message'] {
  display: block !important;
  background: none !important;
  box-shadow: none !important;
  width: 100%;
  [class='content'] {
    margin-top: -20px !important;
    text-align: center;
    width: 100%;
  }
}
