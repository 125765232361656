form[class*='ui form'] {
  > div[class*='field'] > label {
    color: var(--grey2) !important;
  }
  .focus[class*='field'] > label {
    color: black !important;
  }
}

tags.input {
  border-radius: 4px;
}

.domain { 
  align-self: center;
  padding: 0 8px;
  color: var(--grey2);
}

.relative {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 0 !important;
}