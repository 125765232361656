.container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  [class*="ui card"]:last-child {
    margin-top: 0;
  }

  @media (max-width: 1200px){
    > div{
      flex-basis: 100%;
    }
  }
}

.content {
  width: calc(100% - 320px - 1rem);

  [class="w100"] {

    margin: 1.5rem 0 4.125rem !important;
    border-radius: 0.25rem;

  }

  img {
    max-width: 100%;
    margin: 3rem 0;
  }
}

.noSolutions{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem 0;

  img{
    margin: 0;
  }

  span{
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--grey2);
  }
}

.actions {
  padding-top: 1rem !important;

  & > div {
    &:nth-child(2n-1) {
      float: left;
    }
    &:nth-child(2n) {
      float: right;
    }
    .smallerAvatar {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.625rem;
      line-height: 1.5rem;
      margin: 0 0 -0.25rem 0;

      + span {
        color: black;
      }
    }
    > span {
      margin: 0 0.5rem;
      > img {
        margin: 0 !important;
        display: inline !important;
      }
      > span {
        color: black;
        vertical-align: middle;
        margin: 0 0.5rem;
      }
    }
  }
}

.tags {
  display: block;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;

  span {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 2rem;
    padding: 0.25rem 1rem;
    display: inline-block;
    margin: 0 0.5rem 0.5rem 0;
  }
}

.rightSidebar {
  min-width: 19.25rem;
  max-width: 19.25rem;
  margin-left: 2.5rem;
  position: sticky;
  top: 0;

  @media (max-width: 1200px){
    margin-top: 2rem !important;

    /* Hide 'browse solutions' button on mobile */
    button:last-child{
      display: none !important;
    }
  }
}

.margin {
  margin-top: 1rem;
}

.author {
  width: 100%;
  border-top: 1px solid var(--grey);
  color: var(--grey2);
  font-size: 0.875rem;
  margin: 1rem 0 1.5rem 0;
  padding-top: 1rem;

  .smallerAvatar {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.625rem;
    line-height: 1.5rem;
    margin: -0.25rem 0.5rem 0 0;
  }
}

div[class*="ui one cards"] {
  width: 100%;
  > div[class*="ui card"].solution {
    border: 1px solid var(--grey) !important;
    border-radius: 0.375rem !important;
    margin: 0.75rem 0;
    padding: 1.5rem 1rem;
    border-left: 0.25rem solid var(--yellow) !important;
    div[class*='content'] {
      padding: 0;
      display: flex;
      align-items: center;
      border-top: none !important;
      position: relative;

      .submitted,
      [class*='header'] {
        color: var(--grey2);
        font-weight: bold;
        font-size: 0.75rem !important;
        line-height: 1rem !important;
        display: inline-block;
        margin-left: 1rem;
        margin-top: 0 !important;
      }

      .socialIcons,
      .submitted {
        right: 0;
        position: absolute;
      }

      .socialIcons span:nth-last-child(1) {
        margin-right: 0;
      }
    }
  }
}

.info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;

  div {
    padding-left: 0.5rem;
    color: #000;
    font-weight: 600;
  }
}

.counters {
  display: flex;
  margin-bottom: 3rem;

  > div {
    width: 50%;
    display: flex;

    > div {

      strong, small {
        display: block;
      }

      strong {
        color: #000;
        font-size: 2rem;
        line-height: 2.5rem;
      }

      small {
        color: var(--grey3);
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}

.countersSolution, .countersLike {
  margin: 0.5rem 1.25rem 0 0;
  position: relative;
  width: 1.75rem;
  text-align: right;

  &:before {
    content: '';
    border-radius: 0.375rem;
    display: block;
    position: absolute;
    width: 1.375rem;
    height: 1.375rem;
    left: 0;
    top: 0.25rem;
  }

  img, svg {
    margin-left: auto;
    width: auto;
    display: block;
    position: relative;
    z-index: 20;
  }
}

.countersSolution {
  &:before {
    background-color: var(--yellowLighten);
  }

  img, svg {
    height: 1.5rem;
  }
}

.countersLike {
  &:before {
    background-color: var(--blueLight);
  }

  img, svg {
    height: 1.25rem;
  }
}

div[class*="ui one cards"] > div[class*="card"] {
  @for $i from 0 to 5 {
    &.column-$i { 
      border-left-color: var(--column-$i) !important;
      div[class*="meta"] div[class*="label"]::before {
        background: var(--column-$i);
      }
    }
  }
  div[class*="meta"] div[class*="label"] {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 31px;
    color: black;
    padding: 6px 16px;
    &::before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 8px;
      vertical-align: 1px;
    }
  }
}