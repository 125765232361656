.row {
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.iconUser {
  height: 48px;
  width: 48px;
  line-height: 48px;
}

.disabledInputEmail {
  pointer-events: all !important;
}

.select div[class*="selection dropdown"],
.select div[class*="selection dropdown"] div[class*="visible menu transition"] div[role="option"] {
  padding: 13px 16px;
  font-size: 16px;
  line-height: 24px;
}

[class*="fields"] {
  margin-bottom: 1rem !important;
}