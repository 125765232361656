.logo {
  padding: 2rem 0 0 2rem;
  display: flex;
  justify-content: flex-start;

  &[class*="headerTop"] {
    padding: 1.5rem 2rem 1.5rem 0;

    a {
      display: flex;
      height: 3rem;
      width: 3rem;
      border: 1px solid rgba(0,0,0,0.04);
      border-radius: 2.5px;
      padding: 0.25rem;
    }
    span {
      display: none;
    }
  }
  
  span {
    word-spacing: 9999999px;
    width: 60px;
    font-size: 0.75rem;
    line-height: 1rem;
    color: black;
    margin-left: 0.75rem;
    
  }

  img {
    width: 100%;
    height: auto;
    transition: opacity 300ms linear;
  }
}

.loginScreen, .inverted {
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--grey);

  span {
    word-spacing: normal;
    width: 170px;
    font-size: 24px;
    line-height: 28px;
  }
  .actions {
    background: transparent !important;
  }

  &.inverted span{
    color: white;
  }
}