.wrapper {
  width: 100%;
  padding-right: 20px;
}

.add {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--primary);
  padding-left: 48px;
}

.question {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 0 16px 0;
}

.answer {
  font-size: 16px;
  line-height: 24px;
}

.label {
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
  margin: 16px 0 0 0 !important;
}

.error {
  color: var(--red) !important;
  border-color: var(--red) !important;
}

.minimalError[class*="ui error message"] {
  display: block !important;
  background: none !important;
  box-shadow: none !important;
  width: 100%;
  padding: 0 !important;
  position: absolute !important;
  margin: -32px 0 0 !important;
  height: 0;
  [class="content"] {
    width: 100%;
    
    p {
      color: var(--red) !important;
    }
  }
}

textarea.shortInput {
  min-height: 48px !important;
  max-height: 106px !important;
  overflow: hidden;
  margin-bottom: 32px !important;
}

form[class*='ui form'] div[class*='field'] > label.textareaLabel {
  color: var(--grey3) !important;
  float: left;
  margin-bottom: 8px;
  line-height: 19px;
}

.dropdown {
  padding-left: 0;
  height: 48px;
  vertical-align: middle;
  position: relative;

  i[class*='dropdown'] {
    margin-right: 16px;
  }

  button[class*='ui button'] {
    position: absolute;
    top: 12px;
    padding: 4px;
    right: -8px;

    img {
      width: 16px;
      height: 16px;
    }

    &,
    &:hover {
      background: transparent;
    }
  }

  [class*="react-datepicker-wrapper"] [class*="icon input"] {
    input[type="button"] {
      cursor: pointer;
      height: 48px;
      max-height: 48px;
      width: 213px;
      background: var(--grey4);
      border: 1px solid var(--grey);
      box-sizing: border-box;
      border-radius: 4px;
      &:focus {
        background: var(--grey4);
        border-color: var(--grey) !important;
      }
    }
    img {
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      right: 12px;
      top: 11px;
    }
  }

  div[class*='field'] {
    width: 100%;
    margin-top: 32px;
    padding-right: 2em !important;

    [class*='ui small input'] input[type="text"],
    [class*='checkbox'] label {
      font-family: 'Circular Std', sans-serif;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      margin-left: 16px;
    }

    [class*='ui small input'] {
      width: 100% !important;
      input[type="text"] {
        background: transparent;
        width: 100%;
        &::placeholder {
          color: var(--grey2);
        }
      }
    }
  }
}