div[class*='ui card'].solution {
  width: 100%;
  margin: 0 0 48px;
  padding: 0 0 16px 0;
  border-bottom: 1px solid var(--grey) !important;
  div[class*='content'] {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: none !important;
    position: relative;
    padding-bottom: 16px;

    &.actions {
      margin-bottom: 0;
    }

    &[class*='extra'] {
      align-items: center;
    }

    .userWrapper {
      display: flex;
      align-items: center;
    }

    > span,
    .status + span {
      color: var(--grey2);
      font-weight: bold;
      font-size: 12px !important;
      line-height: 16px !important;
      display: inline-block;
      margin: 0;
    }

    .socialIcons,
    .submitted {
      right: 0;
      position: absolute;

      > span {
        margin-left: 2.625rem;
      }
    }

    .socialIcons span:nth-last-child(1) {
      margin-right: 0;
    }

    @media (max-width: 768px) {
      &:not([class*='extra']) {
        flex-wrap: wrap;
        justify-content: space-between;

        > div,
        > span {
          position: relative !important;
          display: block;
          flex-basis: 100%;
        }

        > div {
          margin-bottom: 0.5rem;
        }

        .submitted {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.actions {
  & > div {
    .smallerAvatar {
      width: 24px;
      height: 24px;
      font-size: 10px;
      line-height: 24px;
      margin: 0 0 -4px 0;
      border: 2px solid white;
      border-radius: 50%;
      vertical-align: middle;

      + span {
        font-size: 14px;
        line-height: 20px;
        color: black;
      }
    }
    img.smallerAvatar {
      margin: 0;
    }
    > span {
      margin: 0 0 0 16px;
      > img {
        margin: 0 !important;
        display: inline-block !important;
        visibility: middle;
        width: 16px;
        height: 16px;
      }
      > span {
        color: black;
        vertical-align: middle;
        margin: 0 8px;
      }
    }
  }
}

.actions {
  align-items: flex-end;
  display: flex;
  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 32px;
    margin-left: 42px;
  }

  @media (max-width: 576px) {
    div:first-child > span:last-child {
      display: none;
    }
  }
}

.author {
  align-items: flex-end;
  display: flex;
}

.smallerAvatar {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 24px;
  margin: 0 8px -4px 0;
}

.status {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border-radius: 31px;
  color: black;
  padding: 0.25rem 1rem;
  background-color: var(--grey4);
  &::before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: 1px;
  }
  @for $i from 0 to 5 {
    &.column-$i {
      border-left-color: var(--column-$i) !important;
      &::before {
        background: var(--column-$i);
      }
    }
  }
}

div[class*='ui one cards'] {
  width: 100%;
  > a {
    display: block;
    width: 100%;
    > div[class*='ui card'].solution {
      border: 1px solid var(--grey) !important;
      border-radius: 6px !important;
      margin: 12px 0;
      padding: 1.5rem;
      border-left: 4px solid var(--yellow) !important;

      &:hover {
        border-color: var(--blue);
      }
      div[class*='content'] {
        padding: 0;
        border-top: none !important;
        position: relative;

        &:not([class*='extra']) {
          padding-bottom: 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.04);
          margin-bottom: 1rem;
        }

        .statusWrapper {
          min-width: 200px;
          display: flex;
          justify-content: flex-end;
        }

        .solutionTitle {
          flex-grow: 1;
          font-size: 0.75rem;
          line-height: 1.125rem;
          -webkit-line-clamp: 4;
          color: black;
        }

        .submitted,
        [class*='header'] {
          color: var(--grey2);
          font-weight: bold;
          font-size: 12px !important;
          line-height: 16px !important;
          display: inline-block;
          margin-left: 8px;
          margin-top: 0 !important;
        }

        .username {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: middle;
          color: #aaaaaa;
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        .socialIcons,
        .submitted {
          right: 0;
          position: absolute;
        }

        .socialIcons span:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}

div[class*='ui one cards'] > a > div[class*='card'] {
  @for $i from 0 to 5 {
    &.column-$i {
      border-left-color: var(--column-$i) !important;
      div[class*='meta'] div[class*='label']::before {
        background: var(--column-$i);
      }
    }
  }
  div[class*='meta'] div[class*='label'] {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 31px;
    color: black;
    padding: 6px 16px;
    &::before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 8px;
      vertical-align: 1px;
    }
  }
}
