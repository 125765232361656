.background {
  background: url('/assets/background-gradient.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1;
}

.description {
  margin: 24px 0 48px;
}

.modal{
  max-width: 25.5rem !important;
  background-color: white !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.375rem !important;
  align-self: center;
  justify-self: center;
  padding: 1.5rem !important;
}

.animation {
  opacity: 0;
  animation-name: fadein;
  animation-delay: 1.1s;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logoAnimation {
  animation-name: logo;
  opacity: 0;
  transform: translateY(30px);
  animation-delay: 1s;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}

@keyframes logo {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}