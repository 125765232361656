.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--grey);
  border-radius: 29px;
  padding: 2px 24px 2px 2px;
  min-width: 126px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  img {
    display: block;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    margin-right: 16px;
    overflow: hidden;
  }

  .iconUser {
    margin-left: 2px;
  }

  @media screen and (max-width: 768px){
    padding: 2px;
    min-width: 3.125rem;

    img{
      margin-right: 0;
    }
    span{
      margin-right: 0;
      &:last-child{
        display: none;
      }
    }
  }
}