.contextMenu {
  background: white;
  border: 1px solid var(--grey);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  position: absolute;
  padding: 0;
  z-index: 101;
  
  div {
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: black;
    padding: 8px 16px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.red {
  color: var(--red) !important;
  &:hover {
    background-color: rgba(255, 0, 0, 0.04) !important;
  }
}

.primary {
  color: var(--primary) !important;
  &:hover {
    background-color: var(--primaryLighten) !important;
  }
}