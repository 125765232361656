.container:hover div[class*='DividerWithActions'],
.container:hover + .container div[class*='DividerWithActions'],
.container:hover + div[class*='DividerWithActions'] {
  &::before {
    background-color: var(--primary);
  }
}

.container:hover div[class*='DividerWithActions'] span {
  display: flex;
}

.selectType {
  position: absolute;
  top: 5rem;
  left: 100%;
  margin-left: 1rem !important;

  [class*='dropdown'] [class*='menu'] {
    min-height: 237px;
  }
}

.actionsWrapper {
  flex-direction: column !important;
  margin: 0 !important;
  padding: 0 0 1em;

  > div:not([class*='DividerWithActions']) {
    margin: 0;
    padding: 2rem 0 0;
  }
}

form.container {
  position: relative;
  counter-increment: QuestionSection;

  &:before {
    content: counter(QuestionSection) ".";
    display: block;
    position: absolute;
    top: 5.5rem;
    right: calc(100% + 1rem);

    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 3.25rem;
    color: var(--grey2);

    @media (max-width: 768px){
      top: -1rem;
    }
  }
}

@media (max-width: 768px){
  .container{
    display: flex;
    flex-wrap: wrap;
  }

  .actionsWrapper{
    width: 100%;
  }

  .selectType{
    position: static;
    margin-left: 0 !important;
    width: 100%;

    [class*=field]{
      width: 100%;
      padding-bottom: 0 !important;
    }
  }
}