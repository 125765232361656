.wrapper {
  display: flex;
  flex-direction: row;
  background: white;
}

.segmentGhost {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
  }

  [class*="icons"] {
    background: var(--grey2);
    height: 1rem;
    min-width: 3rem;
    margin-bottom: 0.5rem;
  }

  [class*="meta"]{
    background: var(--grey2);
    height: 1rem;
    width: 35%;
    margin-bottom: 0.5rem;
  }
  [class*="header"]{
    background: var(--grey2);
    height: 2rem;
    min-width: 8rem;
  }
  [class*="description"]{
    &:before {
      content: '';
      display: block;
      background: var(--grey2);
      height: 2rem;
    }
    &:after {
      content: '';
      display: block;
      background: var(--grey2);
      width: 50%;
      height: 2rem;
      margin-top: 0.5rem;
    }
  }
}


@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.content {
  $padding: 2rem;
  max-width: calc(1440px + (8 * $padding));
  min-height: 100vh;
  position: relative;
  left: 0;
  margin: 0 auto;
  width: 100%;
  background: white;
  padding: 0 $padding;
  transition: 200ms ease-in all;
  
  &.userStyles {
    padding: 0 9.25rem;

    @media (max-width: 1024px){
      padding: 0 $padding 0 calc(2 * $padding);
    }
  }
  
  &.showSidebar {
    padding: 0 calc(4 * $padding) 0 calc(4 * $padding);

    @media (max-width: 1024px){
      padding: 0 $padding 0 calc(2 * $padding);
    }
  }

  &.extendedSidebar {
    filter: blur(8px);
    opacity: 0.6;
  }

  > div > div[class*="_container_"] {
    padding-bottom: 6rem;
  }
}

.blockedWrapper {
  .content {
    pointer-events: none;
  }
  .hamburger {
    display: none;
  }
}

.sidebar {
  max-width: var(--sidebarWidth);
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  width: 100%;
  min-height: 100vh;
  background: white;
  transform: translateX(-200px);
  border-right: 1px solid transparent;
  transition: 200ms ease-in all;

  &.showSidebar {
    display: block;
  }

  &.extendedSidebar {
    transform: translateX(0);
    border-color: var(--grey);
  }
}

.links {
  padding: 15rem 0 0 0;

  li {
    list-style: none;
    padding: 0;
  }

  .selected {
    border-left: 0.25rem solid var(--primary);
  }

  a {
    display: block;
    color: black;
    font-weight: bold;
    font-style: normal;
    border-left: 0.25rem solid transparent;
    box-sizing: border-box;
    line-height: 3rem;
    font-size: 1rem;
    padding-left: 2rem;

    &:hover {
      color: var(--primary);
    }
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  a {
    color: var(--grey2);
  }
}

.container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 3rem;
  justify-content: space-between;
  background: white;
  z-index: 100;
  position: sticky;
  top: 0;

  /*
  &::after {
    content: '';
    display: block;
    background: white;
    position: absolute;
    width: 100px;
    height: 97px;
    right: -100px;
  }
  */

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  + .container {
    margin-top: -3rem;
  }

  + div[class*="container"]:not(.container),
  + div[class*="ui segment"] {
    min-height: calc(100vh - 145px - 112px);
    box-shadow: none;
    border: none;
  }

  h1 {
    margin: 0;
    line-height: 1;
  }
}

.footer {
  /* display: flex; */
  display: none;
  align-items: center;
  justify-content: space-between;
  /* height: 80px; */
  /*border-top: 1px solid var(--grey);*/
  background: white !important;
  position: sticky;
  bottom: 0;
  border-left: 2px solid white;
  border-right: 2px solid white;
  margin: 2rem -2px 0;
  z-index: 100;
}

.footerLinks {
  font-size: 0.75rem;
  line-height: 1rem;
  a {
    color: var(--grey2);
    padding: 0 0.25rem;

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child)::after {
      content: '';
      width: 1px;
      height: 0.625rem;
      display: inline-block;
      background-color: var(--grey);
      vertical-align: middle;
      margin-left: 0.375rem;
    }

    &:hover {
      color: black;
    }
  }
}

.newNotification {
  background: var(--blue);
}

.hamburger {
  cursor: pointer;
  display: block;
  position: fixed;
  top: 1.5rem;
  left: 0.25rem;
  z-index: 11;
  padding: 1rem;

  @media (min-width: 1200px) {
    position: absolute;
    left: auto;
    right: 100%;
    margin-right: 3rem;
  }
}

.segment {
  height: 100vh;
  background: none !important;
}


.backLink {
  position: absolute;
  right: 100%;
  margin-right: 4rem;
  top: 2.25rem;
  cursor: pointer;
  height: 1.5rem;

  @media (max-width: 1024px){
    margin-right: 1rem;
  }
}