@import-normalize;
@import '~@yaireo/tagify/dist/tagify.css';
@import './semantic-ui/fonts/fonts.css';

* {
  outline: none;
}

:root {
  --blue: #005DC7;
  --blueLight: #cfe0ff;
  --primary: #005DC7;
  --column: #2430ff;
  --primaryHover: #2430ff;
  --primaryDarken: #0000c2;
  --green: #00bc29;
  --yellow: #ffc700;
  --yellowLighten: #ffeeb1;
  --primaryLighten: #0000ff10;
  --grey: #d9d9d9;
  --grey2: #aaaaaa;
  --grey3: #7a7a7a;
  --grey4: #f0f0f0;
  --red: #ff4949;
  --column0: #005DC7;
  --column1: #005DC7;
  --column2: #005DC7;
  --column3: #005DC7;
  --column4: #005DC7;

  --sidebarWidth: 208px;
  --multiplier: 1;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'Circular Std', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  height: auto;
  font-size: 16px;

  &.dimmable.dimmed {
    background: black url('/assets/background-gradient.jpg');
    background-size: cover;
  }
}

a {
  color: black;
  text-decoration: none;

  &:hover {
    color: var(--primary);
    h1 {
      color: black;
    }
  }
}

.solutionTitle > span > em {
  font-style: normal;
  background-color: var(--yellowLighten);
}

.ui.form textarea {
  font-family: 'Circular Std', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

h1 {
  font-family: 'Circular Std', sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

h2 {
  font-family: 'Circular Std', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: var(--grey3);
}

h3,
h4,
a,
input,
textarea,
.ui,
.header {
  font-family: 'Circular Std', sans-serif !important;
}

.hidden {
  display: none;
}

.hiddenElementToCheckWidth {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
  font-family: 'Circular Std', sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.bold {
  font-style: normal;
  font-weight: bold !important;
}

/* global react-modal styles */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 150ms linear;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* global fix for tagify */
.tagify {
  --tag-bg: var(--grey4);
  --tag-invalid-color: var(--grey2);
  --tag-invalid-bg: transparent;
  --tag-hover: var(--grey4);
  --tag-remove-bg: transparent;
  --tag-remove-btn-bg--hover: transparent;
  .tagify__tag__removeBtn:hover + div > span,
  .tagify__tag.tagify--notAllowed div > span {
    opacity: 1 !important;
  }
  &.error {
    .tagify__tag {
      border: 1px solid var(--red);
    }
    .tagify__tag__removeBtn {
      border-color: var(--red);
      &::after {
        color: var(--red);
      }
    }
  }
}
.tagify.tagify--focus {
  border-color: var(--primary) !important;
}
.tagify__tag-text {
  font-size: 16px;
  line-height: 24px;
}
.tagify__tag {
  border-radius: 31px;
  border: 1px solid var(--grey4);
  & > div {
    border-radius: 31px;
    padding: 4px 16px;
  }
  &.tagify--error,
  &:hover {
    border: 1px solid var(--red);
  }
}
.tagify__tag__removeBtn {
  height: 20px;
  width: 20px;
  border: 1px solid var(--grey2);
  border-radius: 50%;
  margin-right: 6px;
  font: 16px 'Circular Std';

  &::after {
    color: var(--grey2);
    height: 22px;
  }

  &:hover {
    border-color: var(--red);
    &::after {
      color: var(--red);
    }
  }
}
tags.ui.large.tagify {
  max-height: 100px;
  overflow: auto;
}
.tagify__tag.tagify--error,
.tagify__tag.tagify--notAllowed {
  border: 1px solid var(--red);
  &:hover > div::before,
  > div::before {
    box-shadow: 0 0 var(--tag-inset-shadow-size) white inset;
  }
  div > span {
    color: var(--red);
  }
  x.tagify__tag__removeBtn {
    border-color: var(--red);
    &::after {
      color: var(--red);
    }
  }
}
/*******************************
        Global overwrite
*******************************/
.dimmable.dimmed div[class*='App_container'] {
  border: none;
}

.ui.massive.buttons,
.ui.massive.button {
  background: none;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  padding: 20px;
  max-height: 64px;

  &.secondary {
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  + .ui.massive.button {
    margin-top: 16px;
  }
}

.ui.large.buttons .button,
.ui.large.buttons .or,
.ui.large.button,
.ui.big.labels .label,
.ui.big.label {
  height: 3rem;
}

.ui.big.labels .label,
.ui.big.label {
  margin: 2rem 0 !important;
  padding: 0.75rem 1rem;
}

.ui.large.input > input {
  padding: 0.75rem 1rem;
}

.ui.large.input .icon.link img {
  top: 0.75rem;
  position: relative;
  margin: 0 auto;
}

.ui.secondary.buttons .button,
.ui.secondary.buttons .button:focus,
.ui.secondary.button:focus,
.ui.secondary.buttons .button:active,
.ui.secondary.button:active,
.ui.secondary.button {
  background-color: white;
  color: black;
  &:hover {
    background-color: white;
    color: black;
  }
}

.ui.button.underlined {
  text-decoration: underline;
  width: auto !important;
  font-size: 16px;
  line-height: 24px;
  color: white;
  padding: 16px 0;
  background: none;
  display: flex;
}

.ui.delete.button {
  color: var(--red) !important;
}

.ui.nobordered.button {
  background: transparent;
  color: black;
  font-weight: 600;
  line-height: 24px;
  margin-top: -4px;
  padding: 4px 16px;

  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 8px rgba(0, 0, 0, 0.06);
  }
}

.ui.segment {
  opacity: 0.3;
}

.ui.sortable.table thead th {
  border-left: none;
  font-weight: 400;
  color: var(--grey2);
  line-height: 24px;
  padding: 8px 4px;

  &:hover,
  &.sorted,
  &.sorted:hover {
    color: black;
    background: none;
  }
}

.ui.sortable.table tbody:before {
  content: '-';
  display: block;
  line-height: 16px;
  color: transparent;
}

.ui.selection.dropdown {
  padding: 1rem;
  min-width: 10rem;
  &.active {
    z-index: 101 !important;
  }
  .menu {
    > .item {
      padding: 1rem !important;
    }
  }
  > .search.icon,
  > .delete.icon,
  > .dropdown.icon {
    top: 1rem;
  }
}

.modal {
  .ui.form .field > .selection.dropdown {
    min-width: 8rem;
    width: auto;
  }
}

.ui.form input:not([type]):focus,
.ui.form input[type='date']:focus,
.ui.form input[type='datetime-local']:focus,
.ui.form input[type='email']:focus,
.ui.form input[type='number']:focus,
.ui.form input[type='password']:focus,
.ui.form input[type='search']:focus,
.ui.form input[type='tel']:focus,
.ui.form input[type='time']:focus,
.ui.form input[type='text']:focus,
.ui.form input[type='file']:focus,
.ui.form input[type='url']:focus,
.ui.selection.dropdown:focus,
.ui.selection.active.dropdown,
.ui.selection.active.dropdown .menu {
  border-color: var(--primary) !important;
}

.ui.selection.active.dropdown {
  background: rgba(0, 0, 0, 0.3);
}

.tableColumnLastName {
  min-width: 8.75rem;
}

.smallerAvatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  margin: 0;
  border: 2px solid white;
  border-radius: 50%;
}

.placeholderImage {
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    filter: grayscale(100%);
    opacity: 0.4;
    height: 60px !important;
    width: auto !important;
  }
}

.modals {
  .ui.modal {
    padding: 2rem;
    > .header {
      padding: 0 0 1rem;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 2rem;
    }
    > .content {
      padding: 2.5rem 0 4rem;
      font-size: 1rem;
      line-height: 1.5rem;
      .description > p {
        margin-bottom: 2rem;
      }
    }
    > .actions {
      padding: 0;
    }
  }
}

.ui.modal > .actions {
  background: white;
  border-top: none;
}

.ui.dropdown .menu .active.item {
  font-weight: normal;
}

.modals .ui.form .field {
  padding-bottom: 0 !important;
}

.ui.form .field > label {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: black;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label,
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled],
.ui.form .disabled.fields .field,
.ui.form .disabled.field,
.ui.form .field :disabled {
  opacity: 1;
  background-color: var(--grey4) !important;
  color: #000;
}

.ui.form .disabled input[disabled],
.ui.form .disabled input[type='text'],
.ui.form .field.disabled :disabled {
  background-color: var(--grey4) !important;
  color: #000;
}

.ui.form .field.disabled > label,
.ui.form .fields.disabled > label {
  color: black;
}

.ui.basic.table tbody tr .tableColumnName,
.ui.basic.table tbody tr .tableColumnLastName {
  font-weight: bold;
}
.ui.basic.table tbody tr .tableColumnStatus {
  text-align: center;
}

.ui.basic.table tbody tr:hover {
  .tableColumnName,
  .tableColumnTitle,
  .tableColumnName > div,
  .tableColumnLastName {
    color: var(--primary);
    cursor: pointer;
  }
  .tableColumnName,
  .tableColumnTitle {
    border-radius: 0.375rem 0 0 0.375rem;
  }
  .tableColumnEdit {
    border-radius: 0 0.375rem 0.375rem 0;
  }
}

.ui.basic.table td {
  font-size: 1rem;
  padding: 0.5rem 0.25rem;
  line-height: 1.5rem;
  border: none;
}

.ui.inverted.dimmer {
  justify-content: center;
}
.ui.inverted.dimmer,
.ui.inverted.dimmer > .modal {
  background: transparent !important;
}
.ui.inverted.dimmer p {
  color: white;
}
.ui.error.message p {
  color: black;
}

.ui.inverted.dimmer .actions {
  background: transparent;
  padding: 1rem 0;
}
.ui.inverted.dimmer .actions button {
  width: 100%;
  margin: 0;
}
.ui.inverted.dimmer > .ui.modal {
  margin: 0;
  width: 22.5rem;
}

.ui.inverted.primary.buttons .button,
.ui.inverted.primary.button {
  color: var(--primary);
  box-shadow: 0 0 0 1px var(--primary) inset !important;
}

.ui.inverted.primary.buttons .button:hover,
.ui.inverted.primary.button:hover,
.ui.inverted.primary.buttons .button:active,
.ui.inverted.primary.button:active,
.ui.inverted.primary.buttons .button:focus,
.ui.inverted.primary.button:focus {
  background-color: var(--primary);
}
.ui.inverted.dimmer > .ui.modal {
  box-shadow: none;
}
.ui.table {
  margin: 2rem 0 1rem;
  thead tr:first-child > th:last-child {
    cursor: default;
    &:hover {
      color: var(--grey2);
    }
  }
}
/*******************************
       Overwrite error validation
*******************************/
.ui.form .fields .ui.large.input {
  margin-bottom: 1.875rem; /* todo check 2rem */
}

.ui.form .field:last-child {
  padding-bottom: 1.875rem; /* todo check 2rem */
}

.equal.width.fields {
  .ui.large.input {
    margin-bottom: 0;
  }
  .error.field {
    position: relative;
  }
  .field .ui.pointing.prompt.label {
    padding: 0 0.5rem;
  }
}

.ui.form .field .ui.pointing.prompt.label {
  border: none !important;
  color: var(--red) !important;
  margin: 0;
  padding: 0.5rem 0;
  line-height: 1.5rem;
  font-size: 0.75rem;
  position: absolute;
  width: 100%;
  bottom: auto;
  top: 10rem;
  left: 0;
  background: transparent !important;

  &::before {
    content: '';
    display: none;
  }
}
.ui.form .fields.error .field textarea,
.ui.form .fields.error .field select,
.ui.form .fields.error .field input:not([type]),
.ui.form .fields.error .field input[type='date'],
.ui.form .fields.error .field input[type='datetime-local'],
.ui.form .fields.error .field input[type='email'],
.ui.form .fields.error .field input[type='number'],
.ui.form .fields.error .field input[type='password'],
.ui.form .fields.error .field input[type='search'],
.ui.form .fields.error .field input[type='tel'],
.ui.form .fields.error .field input[type='time'],
.ui.form .fields.error .field input[type='text'],
.ui.form .fields.error .field input[type='file'],
.ui.form .fields.error .field input[type='url'],
.ui.form .field.error textarea,
.ui.form .field.error select,
.ui.form .field.error input:not([type]),
.ui.form .field.error input[type='date'],
.ui.form .field.error input[type='datetime-local'],
.ui.form .field.error input[type='email'],
.ui.form .field.error input[type='number'],
.ui.form .field.error input[type='password'],
.ui.form .field.error input[type='search'],
.ui.form .field.error input[type='tel'],
.ui.form .field.error input[type='time'],
.ui.form .field.error input[type='text'],
.ui.form .field.error input[type='file'],
.ui.form .field.error input[type='url'] {
  color: var(--red) !important;
  background: white !important;
  border-color: var(--red) !important;
}
.ui.form .fields.error .field label,
.ui.form .field.error label,
.ui.form .fields.error .field .input,
.ui.form .field.error .input {
  color: var(--red) !important;
}
/*******************************
       Overwrite Card
*******************************/
.ui.circular.labels .label,
.ui.circular.label {
  background-color: white;
  padding: 0.25rem 0.5rem !important;
  height: 2rem;
  vertical-align: middle;
  > * {
    vertical-align: middle;
    margin: 0 0.25rem;
  }
  > img {
    height: 1.25rem !important;
  }
  > span {
    line-height: 1.5rem;
  }
}

.ui.two.cards {
  padding: 0 !important;
  margin: 0 -1rem 0 0 !important;

  > .card {
    width: calc(50% - 1rem);
    margin: 1rem 0.5rem;
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(2n-1) {
      margin-left: 0;
    }
    .ui.circular.labels {
      //position: absolute;
      //top: 140px;
      margin-top: -3rem;
      margin-left: 0.5rem;
      margin-bottom: -2rem;
    }
  }
}

.ui.cards > .card,
.ui.card {
  box-shadow: none !important;
  border: none !important;

  a {
    color: black;
  }
}

.ui.cards > .card > button:first-child,
.ui.card > button:first-child,
.ui.cards > .card > :last-child,
.ui.card > :last-child {
  border-radius: 0 !important;
}

.ui.card > .button {
  width: 100%;
}

.ui.cards > .card > button.ui:first-child,
.ui.card > button.ui {
  border-radius: 0.25rem !important;
}

.ui.cards > .card > a > .content,
.ui.card > a > .content {
  padding: 1rem 0;
}

.ui.cards > .card > a > .image,
.ui.card > a > .image {
  min-height: 11.25rem;
  display: flex;
  align-items: center;

  img {
    border-radius: 0.25rem;
    width: 100%;
    height: auto;
  }
}

.ui.cards > .card .meta *,
.ui.card .meta * {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--grey2);
}

.ui.cards > .card > a > .content > .header:not(.ui),
.ui.card > a > .content > .header:not(.ui) {
  margin-top: 8px;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  min-height: 56px;
  max-height: 56px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ui.cards > .card > a > .content > .meta,
.ui.card > a > .content > .meta {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 24px;
  max-height: 24px;
}

.ui.cards > .card > a > .content > .meta + .description,
.ui.cards > .card > a > .content > .header + .description,
.ui.card > a > .content > .meta + .description,
.ui.card > a > .content > .header + .description {
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  height: 66px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ui.cards > .card > div.cnotent.extra,
.ui.card > div.content.extra {
  padding: 0.75rem 0;
}

.ui.cards > .card > a > .extra,
.ui.card > a > .extra {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--grey2);
  width: 100%;
  border-top: 1px solid var(--grey) !important;
  padding: 0.5rem 0;
}
/*******************************
       Overwrite primaryColor from less
*******************************/
.ui.primary.buttons .button:not(.inverted),
.ui.primary.button:not(.inverted) {
  background-color: var(--primary) !important;
  transition: all linear 300ms;

  &:hover {
    background-color: var(--primaryHover) !important;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.16), 0 0 1rem rgba(0, 0, 0, 0.06);
  }

  &:active {
    background-color: var(--primaryDarken) !important;
  }

  &[disabled],
  &.disabled {
    background-color: var(--grey4) !important;
    color: var(--grey2) !important;
  }
}
.ui.basic.buttons .button:not(.inverted),
.ui.basic.button:not(.inverted) {
  box-shadow: none !important;
  color: #000 !important;
  font-weight: 500 !important;
}

.ui.primary.buttons .button.inverted,
.ui.primary.button.inverted {
  transition: all linear 300ms;
  background: #fff !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  box-shadow: none !important;

  &:hover {
    border: 1px solid var(--primaryHover) !important;
    color: var(--primaryHover) !important;
    box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.16), 0 0 1rem rgba(0, 0, 0, 0.06) !important;
  }

  &:active {
    border-color: var(--primaryDarken) !important;
    color: var(--primaryDarken) !important;
  }

  &[disabled],
  &.disabled {
    color: var(--grey2) !important;
    border: 1px solid var(--grey2) !important;
  }
}

.ui.input.focus > input,
.ui.input > input:focus {
  border-color: var(--primary) !important;
}

/*******************************
       Overwrite toastify
*******************************/
.Toastify__toast {
  border: 1px solid #0000ff;
  box-sizing: border-box;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  padding: 1rem;

  &--success,
  &--error {
    background: #e9f0ff;
    .Toastify__close-button > svg {
      fill: #6c85ff;
    }
  }

  &-container {
    width: auto;
  }

  &-body {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: black;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 1rem;
    > svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}

h3.draftTitle {
  font-weight: bold;
  font-size: 1.5rem !important;
  line-height: 2rem;
  color: #000;

  &.divider {
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      margin-top: 1rem;
      background-color: var(--grey);
    }
  }

  &.publishedTitle {
    margin-top: 2.5rem;
    + div {
      opacity: 1;
    }
  }

  + div {
    opacity: 0.5;
  }

  [class*='Table_tiles_'] {
    margin-top: 0 !important;
  }
}

/*******************************
  Overwrite quill and challenge view
*******************************/
.ql-snow .ql-editor,
.quill,
.ui.two.cards[class*='_content_'] {
  blockquote {
    font-family: 'Circular Std', sans-serif;
    border: none;
    font-style: italic;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--grey3);
    position: relative;
    padding-left: 1.625rem;
    margin: 1rem 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      background: url('/assets/icon-blockquote.svg') no-repeat;
      left: 0;
      top: 0.5rem;
    }
  }
  .description {
    font-family: 'Circular Std', sans-serif;
    display: block;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  h1,
  h2,
  h3 {
    font-family: 'Circular Std', sans-serif;
    display: block;
    width: 100%;
    color: black;
  }

  h1 {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0 0 1.5rem 0;
  }

  h2 {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    color: black;
  }

  h3 {
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--grey3);
    margin: 0;
  }

  p {
    font-family: 'Circular Std', sans-serif;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
  }

  .divider {
    margin: 7rem 0 1.5rem;
    font-weight: bold;
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      margin-top: 1rem;
      background-color: var(--grey);
    }
  }

  [class*='_socialIcons_'] {
    span {
      font-size: 0.875rem;
      line-height: 2rem;
      font-weight: bold;
    }
    img {
      width: 1rem;
      height: 1rem;
    }
  }
}
.quill {
  height: auto !important;
  a {
    text-decoration: underline;
    color: var(--primary);
    &:hover {
      color: var(--primaryHover);
    }
  }
}
.ui.cards > .card > .buttons,
.ui.card > .buttons,
.ui.cards > .card > .button,
.ui.card > .button {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.w-100,
.w100 {
  width: 100%;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
.mb-5 {
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}
.mt-5 {
  margin-top: 3rem;
}

.tableColumnCounter {
  width: 6rem;
}

.tableColumnEdit {
  width: 5rem;
}

@import './mobile.css';
