.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 240px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey4);
  border-radius: 12px 12px 0px 0px;
}

.title {
  padding: 16px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: var(--grey3);
  &::before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: 1px;
  }
}

@for $i from 0 to 5 {
  .column-$i {
    min-width: 240px;
    background-color: rgba(0, 0, 0, 0.04);
    padding: 0.5rem;
    .title {
      border-left-color: var(--column-$i) !important;
      &::before {
        background: var(--column-$i);
      }
    }

    [class*='QuoteList_dropZone_'] > a > div[class*='ui card'] {
      border-left: 4px solid var(--column-$i) !important;
    }
  }
}
