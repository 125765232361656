.title {
  color: var(--grey3) !important;
  float: left;
  margin-bottom: 8px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
}

.question {
  font-size: 20px;
  line-height: 17px;
  font-weight: bold;
  margin: 0 0 8px 0 !important;
}

div.answerInfo.ui.big.label {
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
  margin: 8px 0 64px 0 !important;
  height: auto !important;
  min-height: 3rem;
}
