.comment{
  margin: 2rem 0;

  .commentContainer{
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 0.75rem 0.75rem 0.75rem 0;
    padding: 1rem;

    p{
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      margin: 0;
    }

    &.highlight{
      background-color: rgba(0, 0, 255, 0.04);
    }
  }

  .commentMeta{
    margin-top: 1rem;
    display: flex;

    .commentAuthor{
      flex-grow: 1;
      .avatar{
        width: 1.5rem;
        height: 1.5rem;
        font-size: 0.625rem;
        line-height: 1.5rem;
        margin: 0 0.5rem 0 0;
      }
      h4{
        display: inline-block;
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.5rem;
      }
    }

    .commentDate{
      text-align: right;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--grey3);

      @media (max-width: 768px){
        display: none;
      }
    }
  }
}