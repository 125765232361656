.container{}
.content {
  min-width: 42.375rem;
  margin: auto;

  h4 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--grey3);
    margin: 3rem 0 0.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  section {
    border-top: 2px solid var(--grey4);
    padding-top: 2.5rem;
  }

  @media (max-width: 768px){
    min-width: auto;
  }
}

.sectionWithImage{
  display: flex;

  > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.625rem;

    > label {
      font-size: 0.875rem;
      color: var(--grey3);
      width: 100%;
      display: block;
      margin-bottom: 1rem;
      padding: 0 0.625rem;

      > div:first-child {
        margin-bottom: 0.625rem;
      }

      input, [class*="input"], [class*="large"] > input {
        width: 100% !important;
        height: 3rem;
      }
    }

    &:first-child {
      margin-left: 0;
      margin-right: 1.25rem;
    }
  }

  @media (max-width: 768px){
    flex-wrap: wrap;
    > div{
      flex-basis: 100%;
    }
  }
}
.thumbnail {
  max-width: 235px;
  [class*="InputUpload_wrapper"] {
    width: 100%;
    height: auto;

    [class*="InputUpload_imageContainer_"] > img {
      margin-left: 0 !important;
      height: 200px;
      width: auto;
    }
  }
}
.w50 {
  width: 50% !important;
}

.counters{
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 25%;
    font-size: 0.875rem;
    color: var(--grey2);
    line-height: 1.5rem;

    strong {
      color: #000;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-top: 0.25rem;
      display: block;
    }

    @media (max-width: 768px){
      margin-bottom: 1.25rem;
      width: 50%;
    }
  }
}
.notifications{}
.reset{
  button {
    margin: 2rem 0 3rem !important;
    display: inline-block !important;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px){
    flex-wrap: wrap-reverse;

    > div {
      width: 100%;
      flex-basis: 100%;

      &:first-child a{
        display: block;
        width: 100% !important;

        button{
          width: 100% !important;
        }
      }

      &:last-child{
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

@media (max-width: 768px){
  .avatar{
    padding-left: 0 !important;
  }
}