.wrapper {
  width: 100%;
  padding-right: 20px;
}

.add {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--primary);
  padding-left: 48px;
}

.question {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 0 16px 0;
}

.answer {
  font-size: 16px;
  line-height: 24px;
}

.label {
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
  margin: 16px 0 0 0 !important;
}

.error {
  color: var(--red) !important;
  border-color: var(--red) !important;
}

.minimalError[class*="ui error message"] {
  display: block !important;
  background: none !important;
  box-shadow: none !important;
  width: 100%;
  padding: 0 !important;
  position: absolute !important;
  margin: -32px 0 0 !important;
  height: 0;
  [class="content"] {
    width: 100%;
    
    p {
      color: var(--red) !important;
    }
  }
}

textarea.shortInput {
  min-height: 48px !important;
  max-height: 106px !important;
  overflow: hidden;
  margin-bottom: 32px !important;
}

form[class*='ui form'] div[class*='field'] > label.textareaLabel {
  color: var(--grey3) !important;
  float: left;
  margin-bottom: 8px;
  line-height: 19px;
}

.dropdown {
  padding-left: 16px;
  height: 24px;
  vertical-align: middle;
  position: relative;

  i[class*='dropdown'] {
    margin-right: 16px;
  }

  button[class*='ui button'] {
    display: none;
    position: absolute;
    top: 0;
    padding: 4px;
    left: 28px;

    img {
      width: 18px;
      height: 18px;
    }

    &,
    &:hover {
      background: transparent;
    }
  }

  &:hover button[class*='ui button'] {
    display: block;
  }
  
  div[class*='field'] {
    line-height: 20px;
    padding: 0;
    align-self: baseline;
    width: 100%;
    margin-top: 32px;

    [class*='ui small input'] input[type="text"],
    [class*='checkbox'] label {
      font-family: 'Circular Std', sans-serif;
      border: none;
      font-size: 16px;
      line-height: 24px;
      font-weight: normal;
      padding-left: 31px;
    }

    [class*='ui small input'] {
      width: 100% !important;
      input[type="text"] {
        background: transparent;
        padding: 0;
        width: 100%;
        &::placeholder {
          color: var(--grey2);
        }
      }
    }

    [class*='checkbox'] {
      height: 15px;
    }

    [class*='checkbox'] [class*='box']::before, 
    [class*='checkbox'] [class*='box']::after, 
    [class*='checkbox'] label::before,
    [class*='checkbox'] label::after,
    [class*='checkbox'] input[type="checkbox"], 
    [class*='checkbox'] input[type="radio"] {
      top: 5px !important;
    }

    [class*='checkbox'] input:checked ~ [class*='box']::after, 
    [class*='checkbox'] input:checked ~ label::after {
      top: 2px !important;
    }
  }
}