.text {
  border-bottom: 1px solid var(--grey);
  padding-bottom: 0.5rem;
}

.add {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: var(--primary);
  padding-left: 16px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid var(--grey);
  padding-top: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: white;
  padding-bottom: 1.25rem;
}

.form {
  margin-bottom: 3rem;
}

.fields {
  width: 100%;
  padding: 0 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  [class*='field'] {
    position: relative;
  }
}

.smallContainer {
  max-width: 35.875rem;
  margin: 0 auto;

  [class*='form'] h2 + p {
    margin: 2.5rem 0 1.5rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.domains {
  padding: 0 1rem 1rem 1rem;
}

.remove {
  right: -29px;
  top: 12px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  transition: opacity 150ms linear;
  svg {
    margin: auto;
    path {
      fill: var(--primary);
    }
  }
}

.reorder {
  left: -26px;
  top: 12px;
  position: absolute;
  cursor: grab;
  opacity: 0;
  transition: opacity 150ms linear;
  svg {
    margin: auto;
    path {
      fill: var(--primary);
    }
  }
}

.domain {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  > div {
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
  }
  button {
    opacity: 0;
  }
  &:hover button {
    opacity: 1;
  }
}

.activeUsers {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--grey2);
}

.domainName {
  font-size: 1rem;
  line-height: 1.5rem;
}

.addButton {
  margin-right: 0.5rem !important;
  max-height: 3rem;
}

.column {
  flex-wrap: nowrap !important;
  height: 48px;
  position: relative;
  [class*='field'] {
    margin-right: 8px !important;
  }

  &:hover .remove,
  &:hover .reorder {
    opacity: 1;
  }

  @media (max-width: 768px){
    margin-bottom: 1rem !important;
  }
}

.columns {
  margin-bottom: 40px;
}

@media (max-width: 768px){
  [class*=fields] > [class*="fourteen wide field"]{
    margin: 0 1rem !important;
    padding: 0 !important;

    [class*="ui large input"]{
      margin-bottom: 1rem !important;
    }
  }
  .addButton, .domainNameLabel{
    margin: 0 1rem !important;
    padding: 0 !important;
    width: 100% !important;
    margin-bottom: 2rem !important;
  }
}