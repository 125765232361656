.quill {
  width: 100% !important;
  margin-top: -33px;

  div[class*="ql-container"] {
    height: calc(100vh - 11.125rem);
    background-color: var(--grey4);
    padding: 2rem 8.438rem 0 !important;
    border-bottom: 0;
    font-size: 1rem;
    font-family: 'Circular Std', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &[class*="ql-snow"] {
      transition: opacity 150ms linear;
      overflow-y: auto;
    }

    @media (max-width: 768px){
      padding: 2rem 1rem 0 !important;
    }
  }

  div[class*="ql-clipboard"]{
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
    opacity: 0;
  }



  div[class*="ql-editor"] {
    /* box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    background-color: white;
    padding: 2.5rem 2rem;
    min-height: 100%;
    height: auto;
    overflow-y: initial;
  }

  button[class*="ql-clean"] {
    width: auto;
    span {
      white-space: nowrap;
    }
  }
}