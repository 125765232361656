/* Tablets */ 
@media only screen and 
(max-device-width: 768px) 
{
  [class*="App_content_"] {
    max-width: 100% !important;
    padding: 0 16px !important;
  }
}

/* Mobiles */ 
@media only screen and 
  (max-device-width: 420px) 
{
  [class*="App_container_"] h1 {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .ql-snow .ql-editor h1, 
  .quill h1, 
  .ui.two.cards[class*='_content_'] h1 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  [class*="SolutionForm_breadcrumbs_"] {
    font-size: 1.2em !important;
    line-height: 1.2em !important;
    padding-left: 32px;
  }
  
  .ui.basic.table {
    margin-top: 0;
    thead tr,
    tbody tr {
      display: flex !important;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      th,
      td {
        font-size: 14px;
      }
    }
  }

  [class*="App_container_"] {
    margin-bottom: 1em;
    margin-left: -16px;
    width: 100vw;
    padding: 0 16px;
  }

  [class*="App_hamburger_"] {
    top: 0.55em;
  }

  [class*="User_user_"] {
    min-width: 44px;
    padding: 0;
    img {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }

  [class*="Logo_logo_"][class*="headerTop"] {
    padding: 0.5em 1em 0.5em 3em;
  }

  .ui.two.cards[class*="ChallengeView_content_"] [class*="SolutionTile_status_"] + span,
  [class*="_rightSidebar_"] {
    display: none !important;
  }

  [class*="ChallengesList_challengesList_"] button[class*="primary"],
  [class*="_content_"] {
    width: 100%;
  }
  
  [class*="ChallengesList_switchTileListView_"] {
    margin-right: -10px;
  }

  [class*="ChallengesList_top_"] a > button {
    position: absolute;
    left: 0;
    top: 66px;
  }

  #root [class*="ChallengesList_tilesContainer_"] {
    width: 100% !important;
    margin-top: 72px;

    .ui.two.cards {
      margin: 0 !important;

      > .card {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  [class*="Notification_modal_"] {
    left: 0 !important;
    width: 100vw;
    &::before {
      left: calc(100vw - 89px);
    }
    [class*="Notification_notificationGroup_"]::after {
      width: 100%;
    }
  }

  .ql-editor {
    padding: 0;
    img {
      margin: 1em 0;
    }
  }

  [class*="App_backLink_"] {
    right: auto;
    top: 1.3em;
  }

  .ql-snow .ql-editor .divider, 
  .quill .divider, 
  .ui.two.cards[class*='_content_'] .divider {
    margin-top: 3em !important;
  }
}