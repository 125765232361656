.wrapper {
  display: flex;
  flex-direction: column;
  opacity: inherit;
  border: 0.5rem;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
}

.scrollContainer {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 15.625rem;
}

.dropZone {
  min-height: 0.5rem;
  padding-bottom: 0.5rem;
  height: 100%;

  > a {
    display: block;
    margin-bottom: 0.5rem;
    border: 1px solid var(--grey);
    border-radius: 0.25rem;
    &:hover,
    &[data-is-dragging='true'] {
      border-color: var(--blue);
    }
    &[data-rbd-placeholder-context-id] {
      border-color: transparent !important;
    }
  }

  div[class*='ui card'] {
    width: 100%;
    margin: 0 0 3rem;
    padding: 1rem 1rem 0.625rem 1rem;
    display: block;
    div[class*='content'] {
      padding: 0 0 1rem 0;
      display: block;
      border-top: none;
      position: relative;
      min-height: 1.5rem;

      div[class*='solutionTitle'] {
        width: 100%;
        margin-top: 0.25rem;
        color: black;
        font-size: 0.75rem !important;
        line-height: 1.125rem !important;
      }

      &::after {
        content: none;
      }

      > div {
        color: var(--grey2);
        font-weight: 500;
        font-size: 0.75rem !important;
        line-height: 1rem !important;
        margin: 0;
      }

      &[class*='extra'] {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0.25rem 0 0 0;
        border-top: 1px solid rgba(0, 0, 0, 0.04);

        & > div {
          margin-bottom: 0.375rem;
        }

        span {
          font-size: 0.75rem;
          line-height: 1.5rem;
          color: black;
          vertical-align: middle;
          margin-left: 0.5rem;
        }
        [class*='IconUser_icon_'] {
          width: 1.5rem;
          height: 1.5rem;
          font-size: 0.625rem;
          line-height: 1.5rem;
          margin: 0 0.5rem 0 0;
          border: 0.125rem solid white;

          + span {
            margin: 0;
          }
        }
        span[class='username'] {
          color: var(--grey2);
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          overflow: hidden;
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: normal;
        }
        img {
          height: 1rem;
          vertical-align: middle;

          + span {
            line-height: 1.5rem;
            font-weight: bold;
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
}
