.smallerAvatar {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 24px;
  margin: 0 8px 0 0;
}

.dots {
  cursor: pointer;
  padding: 1px 20px;
  display: inline-block;
  vertical-align: middle;
  svg path {
    fill: var(--primary) !important;
  }
}