.default {
  background: var(--blue);
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.25rem;
  align-items: center;
  text-align: center;
  color: white;
  display: inline-block;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0.375rem;

  &:disabled,
  &[aria-disabled=true] {
    background: rgba(60, 109, 253, 0.45);
  }

  ~ .default {
    margin-left: 0.75rem;
  }
}

.red {
  color: white !important;
  background: var(--red) !important;
}

.primary {
  color: white !important;
  background: var(--primary) !important;
}

.higher {
  padding: 0.75rem 1rem !important;
  line-height: 1.5rem !important;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.margin {
  margin-top: 1rem !important;
}

body [class*='card'] [class*='buttons'].buttonGroup {
  border: 1px solid var(--primary) !important;
  box-sizing: border-box;
  border-radius: 0.25rem !important;
  padding: 0;
  height: auto;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.24), 0 0 6px rgba(0, 0, 0, 0.06);
  position: relative;

  > button[class*='button'] {
    font-weight: bold;
    font-size: 1.25rem !important;
    line-height: 31px;
    color: var(--primary);
  }

  .dropdown [class*='menu'] [class*='selected'] {
    background-color: white 
  }
  
  > button[class*='button'],
  .dropdown {
    background-color: white !important;
  }

  .dropdown {
    position: static;
    max-width: 4rem;
    border-left: 1px solid var(--grey);
    text-align: center;
    padding: 17px;

    &[class*='active'] i {
      transform: rotate(0);
    }

    [class*='visible menu transition'] {
      border: 1px solid var(--primary);
      box-sizing: border-box;
      border-radius: 0 0 0.25rem 0.25rem;
      left: 0 !important;
      margin: -1px -1px 0;
      padding-top: 0.25rem;

      [role='option'] {
        height: 3rem;
        margin: 0.25rem 0;
        font-size: 1rem;
        line-height: 1.5rem;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
