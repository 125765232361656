.parentContainer {
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.container {
  background-color: white;
  display: inline-flex;
  width: 100%;

  > div {
    flex: 1 0 0;
    margin: 0 8px;
    min-width: 16rem;

    &:nth-last-child(1) {
      margin-right: 0;
    }
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}

.noSolutions{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 4rem 0;

  span{
    margin-top: 1.5rem;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--grey2);
  }
}

div[class*="ui card"].solution {
  width: 100%;
  margin: 0 0 48px;
  padding: 0 0 16px 0;
  display: block;
  border-bottom: 1px solid var(--grey) !important;
  div[class*='content'] {
    padding: 0;
    display: flex;
    align-items: center;
    border-top: none !important;
    position: relative;
    margin-bottom: 16px;

    > span {
      color: var(--grey2);
      font-weight: bold;
      font-size: 12px !important;
      line-height: 16px !important;
      display: inline-block;
      margin: 0;
    }

    .socialIcons,
    .submitted {
      right: 0;
      position: absolute;
    }

    .socialIcons span:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.actions {
  & > div {
    &:nth-child(2n-1) {
      float: left;
    }
    &:nth-child(2n) {
      float: right;
    }
    .smallerAvatar {
      width: 24px;
      height: 24px;
      font-size: 10px;
      line-height: 24px;
      margin: 0 0 -4px 0;
      border: 2px solid white;

      + span {
        font-size: 14px;
        line-height: 20px;
        color: black;
      }
    }
    > span {
      margin: 0 0 0 16px;
      > img {
        margin: 0 !important;
        display: inline-block !important;
        visibility: middle;
        width: 16px;
        height: 16px;
      }
      > span {
        color: black;
        vertical-align: middle;
        margin: 0 8px;
      }
    }
  }
}

.actions {
  align-items: flex-end;
  display: flex;
  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 32px;
    margin-left: 42px;
  }
}

.author {
  align-items: flex-end;
  display: flex;
}

.smallerAvatar {
  width: 24px;
  height: 24px;
  font-size: 10px;
  line-height: 24px;
  margin: 0 8px -4px 0;
}