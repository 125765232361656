.wrapper {
  width: 100%;
  padding-right: 20px;
}

.question {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 0 16px 0;
}

.answer {
  font-size: 16px;
  line-height: 24px;
}

.label {
  font-size: 16px !important;
  line-height: 24px !important;
  width: 100%;
  height: 96px !important;
  margin: 16px 0 0 0 !important;
}

.textarea {
  min-height: 124px;
  height: 124px;
  max-height: 124px;
}

.error {
  color: var(--red) !important;
  border-color: var(--red) !important;
}

.minimalError[class*="ui error message"] {
  display: block !important;
  background: none !important;
  box-shadow: none !important;
  width: 100%;
  padding: 0 !important;
  position: absolute !important;
  margin: 0 !important;
  height: 0;
  [class="content"] {
    width: 100%;
    p {
      color: var(--red) !important;
    }
  }
}

textarea.longInput {
  min-height: 48px !important;
  max-height: 106px !important;
  overflow: hidden;
}

form[class*='ui form'] div[class*='field'] > label.textareaLabel {
  color: var(--grey3) !important;
  float: left;
  margin-bottom: 8px;
  line-height: 19px;
}