@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std Book'), local('Circular-Std-Book'),
      url('./CircularStd-Book.woff2') format('woff2'),
      url('./CircularStd-Book.woff') format('woff'),
      url('./CircularStd-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular Std';
  src: local('Circular Std Medium'), local('Circular-Std-Medium'),
      url('./CircularStd-Medium.woff2') format('woff2'),
      url('./CircularStd-Medium.woff') format('woff'),
      url('./CircularStd-Medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}