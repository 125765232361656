.trigger {
  width: 43px;
  cursor: pointer;
}

.container {
  max-width: 170px;
  margin-bottom: 32px;

  @media (max-width: 768px){
    max-width: 7rem;
    margin-right: 2.6875rem;
    margin-bottom: 0;
  }
}