.icon {
  text-align: center;
  background-color:rgba(0, 0, 0, 0.03);
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: black;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 10px;
}