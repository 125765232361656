.background {
  background: url('/assets/background-gradient.jpg');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 1;
}

.modal{
  max-width: 25.5rem !important;
  min-height: 23.9375rem !important;
  background-color: white !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.375rem !important;
  align-self: center;
  justify-self: center;
  padding: 1.5rem !important;
}

.buttons{
  button:first-child{
    width: 100%;
    margin-left: 0 !important;
  }

  button:not(:first-child){
    color: black !important;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin: 24px 0;
}

.description {
  margin: 24px 0;
}

.animation {
  opacity: 0;
  animation-name: fadein;
  animation-delay: 1.4s;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logoAnimation {
  animation-name: logo;
  transform: translateY(100px);
  opacity: 0;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes logo {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}