.divider {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 22px;
  line-height: 22px;
  align-content: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background-color: var(--grey2);
    margin-top: 10px;
  }
  &.fullWidth::before {
    width: 100%;
  }

  span {
    display: none;
    height: 22px;
    background-color: white;
    padding-left: 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &.addButton {
      position: absolute;
      background-color: transparent;
      width: 100%;
      left: 0;
      top: 0;
      padding-left: calc(50% - 11px);
      z-index: 1;
    }

    &.arrowDown {
      transform: rotate(180deg);
      padding-left: 0;
      padding-right: 12px;
    }

    &.arrowUp,
    &.arrowDown,
    &.remove {
      left: 0;
      svg {
        margin: auto;
        path {
          fill: var(--primary);
        }
      }
    }
  }

  &:hover {
    &::before {
      background-color: var(--primary);
    }
    span {
      display: flex;
    }
  }
}

.iconAdd {
  background-color: white;
  path {
    fill: var(--primary);
  }
}