.row {
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.iconUser {
  height: 48px;
  width: 48px;
  line-height: 48px;
}

.disabledInputEmail {
  pointer-events: all !important;
}